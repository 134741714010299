import React from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import { modifyImageKeys } from '@utils/modifyImageKeys'
import ElementImageFile from '@components/global/element/ImageFile'
import ImageBannerBg from '/content/assets/components/our-prestigious-portfolio/background.jpg'
import laptopPlaceholder from '/content/assets/components/our-prestigious-portfolio/laptop.png'
import SvgArrowAlt from '@svg/arrowAlt.inline'
import * as cx from './PrestigiousPortfolio.module.scss'

const PrestigiousPortfolio = ({
  wrapperClassName = '',
  title = '',
  subtitle = '',
  description = '',
  buttonLabel = '',
  buttonUrl = '',
  portfolioItems,
}) => {
  return (
    <section
      className={`${cx.section} ${wrapperClassName}`}
      style={{ backgroundImage: `url(${ImageBannerBg})` }}
    >
      <div className={cx.heading}>
        <h2 className="heading-5">{title}</h2>
        <p className="subtitle-5">{subtitle}</p>
      </div>
      <div className={cx.carousel}>
        <img
          src={laptopPlaceholder}
          width={851}
          height={513}
          className={cx.placeholder}
        />
        <Splide
          hasTrack={false}
          tag="div"
          options={{
            type: 'loop',
            rewind: false,
            arrows: false,
            pagination: false,
            perPage: 1,
            autoHeight: false,
            lazyLoad: 'sequential',
            autoplay: true,
            interval: 5000,
            autoWidth: true,
            focus: 'center',
            isNavigation: true,
          }}
        >
          <SplideTrack>
            {portfolioItems.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <div className={cx.item}>
                    <ElementImageFile
                      src={modifyImageKeys(item.image)}
                      alt={item.image.alt}
                      splideLazyload={true}
                    />
                  </div>
                </SplideSlide>
              )
            })}
          </SplideTrack>
        </Splide>
      </div>

      <div className={cx.footing}>
        <p className="subtitle-5">{description}</p>
        <Link to={`${buttonUrl}`} className="primary-button large">
          {buttonLabel} <SvgArrowAlt />
        </Link>
      </div>
    </section>
  )
}

PrestigiousPortfolio.propTypes = {
  wrapperClassName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  portfolioItems: PropTypes.array,
}

export default PrestigiousPortfolio
