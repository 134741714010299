import React from 'react'

const SvgPlayAlt = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 61 62"
      style={{ enableBackground: 'new 0 0 61 62' }}
      className={`fill-current ${className}`}
      fill="none"
    >
      <path d="M0 58.1459C0 60.376 2.34694 61.8265 4.34164 60.8292L58.6334 33.6833C60.8446 32.5777 60.8446 29.4223 58.6334 28.3167L4.34164 1.17082C2.34694 0.173469 0 1.62396 0 3.8541V58.1459Z" />
    </svg>
  )
}

export default SvgPlayAlt
