import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { modifyImageKeys } from '@utils/modifyImageKeys'
import ElementImageFile from '@components/global/element/ImageFile'
import ModalVideo from '@components/global/modal/Video'
import ImageBannerBg from '/content/assets/components/video-design-dna/background.jpg'
import SvgArrowAlt from '@svg/arrowAlt.inline'
import SvgPlayAlt from '@svg/playAlt.inline'
import * as cx from './VideoDna.module.scss'

const VideoDna = ({
  wrapperClassName = '',
  title = '',
  description = '',
  videoTitle = '',
  videoSubtitle = '',
  videoUrl = '',
  buttonLabel = '',
  videoPlaceholder,
}) => {
  const modalVideoFunc = useRef()

  return (
    <section
      className={`${cx.section} ${wrapperClassName}`}
      style={{ backgroundImage: `url(${ImageBannerBg})` }}
    >
      <ModalVideo childFunc={modalVideoFunc} url={`${videoUrl}`} />

      <div className={cx.videoPlaceholder}>
        <ElementImageFile
          src={modifyImageKeys(videoPlaceholder)}
          alt="1999 Founded on Venice Beach"
        />

        <div className={cx.videoTitle}>
          <strong>{videoTitle}</strong>
          {videoSubtitle}
        </div>

        <button
          className={cx.play}
          onClick={() => {
            modalVideoFunc.current.openModal()
          }}
        >
          <span className="sr-only">Play Button</span>
          <SvgPlayAlt />
        </button>
      </div>

      <div className={cx.videoDescription}>
        <div className={cx.videoContent}>
          <h2 className="subtitle-2">{title}</h2>
          <p className="subtitle-5">{description}</p>
          <button
            className="primary-button large"
            onClick={() => {
              modalVideoFunc.current.openModal()
            }}
          >
            {buttonLabel} <SvgArrowAlt />
          </button>
        </div>
      </div>
    </section>
  )
}

VideoDna.propTypes = {
  wrapperClassName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  videoTitle: PropTypes.string,
  videoSubtitle: PropTypes.string,
  videoUrl: PropTypes.string,
  buttonLabel: PropTypes.string,
  videoPlaceholder: PropTypes.any,
}

export default VideoDna
