import React from 'react'
import PropTypes from 'prop-types'
// import { Link, navigate } from "gatsby";
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { wpautop } from '@utils/wpautop'
import { removeElementTags } from '@utils/removeElementTags'
import { modifyImageKeys } from '@utils/modifyImageKeys'
import parse from 'html-react-parser'

// Components
import Breadcrumbs from '@components/seo/Breadcrumbs'
import ReviewsTrustedByTheBest from '@components/global/Reviews/TrustedByTheBest'

// Element
import ElementImageFile from '@components/global/element/ImageFile'

// CSS
import * as cx from './ThankYouBanner.module.scss'

// Svg
import SvgArrowAlt from '@svg/arrowAlt.inline'

const ThankYouBanner = ({
  breadcrumbs,
  title = 'You’re one step closer <br />to a world-class website.',
  titleClassName = 'heading-5',
  paragraph = 'Thank you for choosing Agent Image! \nOur team will get in touch shortly, so keep your lines open.',
  buttonLabel = '',
  bannerImage,
  brandsTitle = '',
  brandsSubtitle = '',
  brands,
  version = '1',
}) => {
  const { mainDevice } = useStaticQuery(graphql`
    query ThankYouBannerImage {
      mainDevice: file(
        relativePath: { eq: "thank-you/banner-v2-devices.png" }
      ) {
        id
        ...ThankYouBannerImageOptimize
      }
    }

    fragment ThankYouBannerImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  const navigateBack = () => {
    if (window.previousPath === '' || window.previousPath === 'thank-you') {
      navigate('/')
    } else {
      navigate(-1)
    }
  }

  return (
    <section className={`${cx.section}`}>
      <div
        className={`${cx.hero}`}
        style={{
          backgroundImage: `url(https://resources.agentimage.com/redesign/local/thank-you/banner-bg.jpg)`,
        }}
      >
        <div className={`container ${cx.container}`}>
          <Breadcrumbs wrapperClassName={cx.breadcrumbs} data={breadcrumbs} />

          <div className={`row ${cx.row}`}>
            <div className={`col ${cx.heading}`}>
              <h2 className={titleClassName}>{parse(title)}</h2>

              {parse(wpautop(paragraph, `subtitle-5 ${cx.paragraph}`))}

              <div className={cx.button}>
                <button className="primary-button large" onClick={navigateBack}>
                  <span>Go&nbsp;</span>
                  {buttonLabel} <SvgArrowAlt />
                </button>
              </div>
            </div>
            <div className={`col ${cx.device}`}>
              <ElementImageFile
                src={modifyImageKeys(bannerImage)}
                alt={removeElementTags(title)}
                lazy={true}
              />
            </div>
          </div>
        </div>
      </div>

      <ReviewsTrustedByTheBest
        alt={true}
        title={brandsTitle}
        subtitle={brandsSubtitle}
        brands={brands}
      />
    </section>
  )
}

ThankYouBanner.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  buttonLabel: PropTypes.string,
  bannerImage: PropTypes.object,
  brandsTitle: PropTypes.string,
  brandsSubtitle: PropTypes.string,
  brands: PropTypes.any,
  version: PropTypes.oneOf(['1', '2']),
}

export default ThankYouBanner
