import React from 'react'
import { removeElementTags } from '@utils/removeElementTags'

const ElementImage = ({
  src,
  srcSet,
  width,
  height,
  alt,
  className,
  sizes,
  splideLazyload = false,
  lazy = true,
}) => {
  // if (splideLazyload) {
  //   return (
  //     <img
  //       src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
  //       data-splide-lazy={src}
  //       data-splide-lazy-srcset={srcSet}
  //       sizes={sizes}
  //       alt={removeElementTags(alt)}
  //       width={width}
  //       height={height}
  //       className={className}
  //     />
  //   )
  // } else
  if (srcSet !== '') {
    return (
      <img
        loading={lazy ? 'lazy' : 'eager'}
        src={src}
        srcSet={srcSet}
        sizes={sizes}
        alt={removeElementTags(alt)}
        width={width}
        height={height}
        className={className}
      />
    )
  } else {
    return (
      <img
        loading={lazy ? 'lazy' : 'eager'}
        src={src}
        alt={removeElementTags(alt)}
        width={width}
        height={height}
        className={className}
      />
    )
  }
}

export default ElementImage
