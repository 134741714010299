import React from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'

import ElementImageFile from '@components/global/element/ImageFile'
import ImageBannerBg from '/content/assets/components/top-post-per-category/background.jpg'
import * as cx from './TopPostPerCategory.module.scss'

const TopPostPerCategory = ({ wrapperClassName = '' }) => {
  const {
    allWpPost: { edges },
  } = useStaticQuery(graphql`
    {
      allWpPost(limit: 4) {
        edges {
          node {
            id
            title
            slug
            uri
            excerpt
            primaryCategory {
              node {
                name
                slug
              }
            }
            featuredImage {
              node {
                width
                height
                publicUrl
                sizes
                srcSet
                altText
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section
      className={`${cx.section} ${wrapperClassName}`}
      style={{ backgroundImage: `url(${ImageBannerBg})` }}
    >
      <div className={cx.heading}>
        <h2 className="heading-5">Agent Image Blog</h2>
        <p className="subtitle-5">
          Learn the tips and tools you need to succeed at real estate marketing
          in the digital age.
        </p>
      </div>

      <div className={cx.items}>
        {edges.map((item, index) => {
          return (
            <div key={item.node.id} className={cx.item}>
              <Link to={item.node.uri} className="image-responsive">
                <canvas width={620} height={204}></canvas>
                <ElementImageFile
                  src={item.node?.featuredImage?.node?.publicUrl}
                  width={item.node?.featuredImage?.node?.width}
                  height={item.node?.featuredImage?.node?.height}
                  srcset={item.node?.featuredImage?.node?.srcSet}
                  sizes={item.node?.featuredImage?.node?.sizes}
                  alt={item.node.title}
                  lazy={true}
                />
              </Link>
              <div className={cx.hr}></div>
              <div className={cx.content}>
                <div className={`badge`}>
                  {item.node.primaryCategory?.node?.name
                    ? item.node.primaryCategory?.node?.name
                    : 'Blog'}
                </div>
                <h3 className="subtitle-2">{item.node.title}</h3>
                <p className="default-body large line-clamp-4">
                  {parse(item.node.excerpt)}
                </p>
                <Link to={`${item.node.uri}`} className="primary-button">
                  Read the Blog
                </Link>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

TopPostPerCategory.propTypes = {
  wrapperClassName: PropTypes.string,
}

export default TopPostPerCategory
