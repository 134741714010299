import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'

// Components
import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import ThankYouBanner from '@components/global/banner/ThankYouBanner'
import ReviewsServiceSetsUsApart from '@components/global/Reviews/ServiceSetsUsApart'
import PrestigiousPortfolio from '@components/pages/thank-you/PrestigiousPortfolio'
import VideoDna from '@components/pages/thank-you/VideoDna'
import TopPostPerCategory from '@components/global/blog/TopPostPerCategory'
import ProductAccessAlt from '@components/global/product/AccessAlt'

const ThankYouTemplate = ({
  data: { page, bannerDevice },
  pageContext: { seo, uri },
}) => {
  return (
    <LayoutInnerPage>
      <Seo title={page.title} seo={seo} uri={uri} />
      <ThankYouBanner
        breadcrumbs={page?.seo?.breadcrumbs}
        title={page?.thankYou?.banner?.title}
        paragraph={page?.thankYou?.banner?.description}
        buttonLabel={page?.thankYou?.banner?.button_label}
        bannerImage={page?.thankYou?.banner?.image}
        brandsTitle={page?.thankYou?.brands?.title}
        brandsSubtitle={page?.thankYou?.brands?.subtitle}
        brands={page?.thankYou?.brands?.items}
      />
      <ReviewsServiceSetsUsApart
        title={page?.thankYou?.reviews?.title}
        subtitle={page?.thankYou?.reviews?.subtitle}
        backgroundUrl={page?.thankYou?.reviews?.review_background?.url}
        reviewItems={page?.thankYou?.reviews?.items}
      />
      <PrestigiousPortfolio
        title={page?.thankYou?.portfolios?.title}
        subtitle={page?.thankYou?.portfolios?.subtitle}
        description={page?.thankYou?.portfolios?.description}
        buttonLabel={page?.thankYou?.portfolios?.button_label}
        buttonUrl={page?.thankYou?.portfolios?.button_url}
        portfolioItems={page?.thankYou?.portfolios?.items}
      />
      <VideoDna
        title={page?.thankYou?.history?.title}
        description={page?.thankYou?.history?.description}
        videoTitle={page?.thankYou?.history?.video_title}
        videoSubtitle={page?.thankYou?.history?.video_subtitle}
        videoUrl={page?.thankYou?.history?.video_url}
        buttonLabel={page?.thankYou?.history?.button_label}
        videoPlaceholder={page?.thankYou?.history?.video_thumbnail}
      />
      <TopPostPerCategory />
      <ProductAccessAlt />
    </LayoutInnerPage>
  )
}

export default ThankYouTemplate

export const ThankYouPageQuery = graphql`
  query ThankYouPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")

      seo {
        breadcrumbs {
          text
          url
        }
      }

      thankYou {
        banner {
          title
          description
          button_label
          button_url
          image {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }
        reviews {
          title
          subtitle
          review_background {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          items {
            name
            url
            content
            logo {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
        portfolios {
          title
          subtitle
          description
          button_label
          button_url
          items {
            url
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
        history {
          title
          description
          video_title
          video_url
          video_subtitle
          button_label
          video_thumbnail {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }
        brands {
          title
          subtitle
          items {
            url
            logo {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
      }
      productInputs {
        form {
          version
          title
          subtitle
          submit
          model
          background {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }
      }
    }
  }

  fragment ThankYouPageImage on File {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
    }
  }
`
